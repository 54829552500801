import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import ProductSlider from 'react-slick/lib';
import Rating from 'react-rating';
import { isMobile, isTablet } from 'mobile-device-detect';
import { Badge, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import Hashids from 'hashids/cjs';
import { Skeleton } from '@material-ui/lab';
import Helmet from 'react-helmet';
import { filterOptions, requestProducts as requestProductsAction } from './actions';
import {
  selectProductErrorMessage,
  selectProducts,
  selectProductsCurrentPage,
  selectProductsHasMore,
  selectProductsLoading,
} from './selectors';
import PageWrap from '../../components/page-wrap';
import './style.scss';

// FIXME Consider dynamically importing these based on the file name
import Icon from '../../components/Icon';
import { config } from '../../settings';
import Placeholder from '../../../config/placeholder.config';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import CustomScroll from 'react-custom-scrollbars';
import BuyButton from '../../components/BuyButton';
import Product from '../../models/Product';
import MaterialButton from '@material-ui/core/Button';
import * as Sentry from '@sentry/react';
import { Resize } from '../../utils/imageUtils';
import { Transducer } from '../../utils/transducer';
import ModalDialog from '../../components/ModalDialog';
import ShopFilter from '../../components/shop-filters';
import DynamicAd from '../../components/dynamic-ad';

const hashids = new Hashids('', 12);

const types = [
  'Samplers',
  'Bundles',
  'Boxes',
  '5 Packs',
  'Singles',
  'Jars',
  'Tins',
];

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Icon name="chevron-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Icon name="chevron-left" />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const defaults = {
  selectedSort: filterOptions.sort,
  selectedType: null, // 'Samplers',
  selectedOrigin: null,
  selectedWrapper: null,
  selectedLength: null,
  selectedGauge: null,
  selectedStrength: null,
  selectedFilterOptions: filterOptions,
  selectedRating: filterOptions.rating,
  selectedPriceRange: [filterOptions.price_min, filterOptions.price_max],
  listType: 'list',
};

// FIXME These 3 similar views should really be reusable components that pass in options for actions and filter / sort choices etc
function ShopAll({ products, auth, history, filterAllProducts, match }) {
  const [showSortDrawer, setShowSortDrawer] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  const [loadingSections, setLoadingSections] = useState(true);
  const [sections, setSections] = useState([]);
  const [state, setState] = useState(defaults);

  const items = [{
    content: 'Most Relevant',
    value: 'relevant',
    onClick: () => {
      sortResults({
        target: {
          value: 'relevant',
        },
      });
    },
  }, {
    content: 'Discount',
    value: 'discount',
    onClick: () => {
      sortResults({
        target: {
          value: 'discount',
        },
      });
    },
    // FIXME Not enough ratings for this yet
    // }, {
    //   content: 'Highest User Rating',
    //   value: 'rating',
    //   onClick: () => {
    //     sortResults({
    //       target: {
    //         value: 'rating',
    //       },
    //     });
    //   },
  }, {
    content: 'Name: A to Z',
    value: 'a_z',
    onClick: () => {
      sortResults({
        target: {
          value: 'a_z',
        },
      });
    },
  }, {
    content: 'Name: Z to A',
    value: 'z_a',
    onClick: () => {
      sortResults({
        target: {
          value: 'z_a',
        },
      });
    },
  }, {
    content: 'Price: Low to High',
    value: 'low_high',
    onClick: () => {
      sortResults({
        target: {
          value: 'low_high',
        },
      });
    },
  }, {
    content: 'Price: High to Low',
    value: 'high_low',
    onClick: () => {
      sortResults({
        target: {
          value: 'high_low',
        },
      });
    },
  }];

  useEffect(() => {
    console.log('Getting products...');
    const urlParams = new URLSearchParams(window.location.search);
    const venueHashId = urlParams.get('venue_id');
    if (venueHashId) {
      const venueId = hashids.decode(venueHashId)[0];
      const options = { ...state.selectedFilterOptions };
      options.venue_id = venueId;
      setState({
        ...state,
        selectedFilterOptions: options,
      });
      getSections(venueId);
    } else {
      getSections();
    }
  }, [window.location.search]);

  useEffect(() => {
    const { venueId } = match.params;
    if (venueId) {
      const options = { ...state.selectedFilterOptions };
      options.venue_id = venueId;
      setState({
        ...state,
        selectedFilterOptions: options,
      });
      getSections(venueId);
    }
  }, [match.params.venueId]);

  const getImage = (product) => {
    // console.log('Getting image for product');
    // console.log(product);

    if (product.image_url) {
      if (product.image_url.indexOf('http') === -1) {
        return `https://${product.image_url}`;
      }
      return Resize.thumbnail(product.image_url, {
        cropType: 'fit',
        additionalParams: '&pad=true&bg_color=ffffff',
      });
    }

    if (product.images) {
      console.log('Product images:');
      console.log(product.images);
      if (Array.isArray(product.images) && product.images.length > 0) {
        console.log('Product images is an array');
        console.log(product.images[0].url);
        if (product.images[0].url.indexOf('http') === -1) {
          return Resize.thumbnail(`https://${product.images[0].url}`, {
            cropType: 'fit',
            additionalParams: '&pad=true&bg_color=ffffff',
          });
        }
        return Resize.thumbnail(product.images[0].url, {
          cropType: 'fit',
          additionalParams: '&pad=true&bg_color=ffffff',
        });
      }
      // FIXME This is an error with the SQL on the server - it should be fixed there to return an array
      if ('url' in product.images) {
        console.log('Product images is an object');
        if (product.images.url.indexOf('http') === -1) {
          return Resize.thumbnail(`https://${product.images.url}`, {
            cropType: 'fit',
            additionalParams: '&pad=true&bg_color=ffffff',
          });
        }
        return Resize.thumbnail(product.images.url, {
          cropType: 'fit',
          additionalParams: '&pad=true&bg_color=ffffff',
        });
      }
    }

    return Transducer.getPreference('dark_mode') === 'true' ? PlaceholderDark.cigar : Placeholder.cigar;
  };

  const productTitle = (product) => {
    if (product.contents && product.contents.length > 0 && product.contents[0].vitola) {
      return `${product.name} ${product.contents[0].vitola.formatted_name}`;
    }
    return product.name;
  };

  const packageTitle = (product) => {
    let title = '';
    if (product.packaging) {
      if (product.packaging.type === 'Sampler') {
        let count = 0;
        for (let i = 0; i < product.contents.length; i++) {
          const content = product.contents[i];
          count += content.quantity;
        }
        title = `${product.packaging.type} of ${count}`;
      } else if (product.packaging.type === 'Single') {
        title = product.packaging.type;
      } else {
        title = `${product.packaging.type} of ${product.packaging.quantity}`;
      }
    }
    return title;
  };

  const showProductDetail = (product) => {
    if (product && product.id) {
      if (window.analytics) {
        window.analytics.track('Product Clicks', {
          product_id: product.id,
          venue_id: product.venue_id,
          user_id: auth && auth.user && auth.user.id,
        });
      }
      history.push({ pathname: `/products/${hashids.encode(product.id)}`, state: { product } });
    }
  };

  /**
   * Gets main sections for shop all view (shop home page) - this opens this section up to be dynamic based on the user's
   * location and recommendations so we can do like eBay, Amazon, Vivino, and others by making this page specific to each
   * customer and their patterns
   */
  const getSections = (venueId) => {
    const { user } = auth;
    if (user && user.id) {
      axios.get(`${config.shopEndPoint}/users/${user.id}/saved`, {
        params: { venue_id: venueId },
      }).then((response) => {
        if (response.data && response.data.length > 0) {
          // Always put it at the top of the page
          console.log('Added try list to state');
          setSections((prev) => ([{ title: 'Saved From Your Try List', products: response.data }, ...prev]));
        }
      }).catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
      axios.get(`${config.shopEndPoint}/users/${user.id}/products/all`, {
        params: { venue_id: venueId },
      }).then((response) => {
        console.log('Shop Sections:');
        console.log(response);
        if (response.data) {
          console.log('Added sections to state');
          setSections((prev) => ([...prev, ...response.data]));
          setLoadingSections(false);
        } else {
          // TODO Show filter page 1 instead?
          setLoadingSections(false);
        }
      }).catch((err) => {
        console.log(err);
        setLoadingSections(false);
        Sentry.captureException(err);
      });
    } else {
      axios.get(`${config.shopEndPoint}/products/all`, {
        params: { venue_id: venueId },
      }).then((response) => {
        console.log('Shop Sections:');
        console.log(response);
        if (response.data) {
          console.log('Added sections to state');
          setSections(response.data);
          setLoadingSections(false);
        } else {
          // TODO Show filter page 1 instead?
          setLoadingSections(false);
        }
      }).catch((err) => {
        console.log(err);
        setLoadingSections(false);
        Sentry.captureException(err);
      });
    }
  };

  const toggleSideDrawer = () => {
    setShowFilterDrawer((show) => !show);
  };

  const sortResults = (event) => {
    const { value } = event.target;
    const options = { ...state.selectedFilterOptions };
    options.sort = value;

    setState({
      ...state,
      selectedFilterOptions: options,
      selectedSort: value,
    });
    const { venueId } = match.params;
    if (venueId) {
      getSections(venueId);
    } else {
      getSections();
    }
  };

  const renderShopFilter = () => (
    <ShopFilter
      showRatingFilter={false}
      defaultState={defaults}
      sortItems={items}
      selectedSort={state.selectedSort}
      showSortDrawer={showSortDrawer}
      onToggleSortDrawer={() => setShowSortDrawer((prevValue) => !prevValue)}
      onChangeSort={(value) => {
        setShowSortDrawer(false);
        history.push({ pathname: '/shop/products',
          state: {
            options: {
              ...state.selectedFilterOptions,
              sort: value,
            },
          },
        });
      }}
      filterOptions={state.selectedFilterOptions}
      onChangeFilterOptions={(options) => setState({ ...state, selectedFilterOptions: options })}
      showFilterDrawer={showFilterDrawer}
      onResetFilters={() => setState(defaults)}
      onShowFilterDrawer={(value) => setShowFilterDrawer(value)}
      onSelectedShape={(shape) => setSelectedShape(shape)}
      onFilterProducts={(page, options) => {
        if (selectedShape) {
          options.shape = selectedShape;
        } else {
          options.shape = null;
        }
        if (state.selectedSort) {
          options.sort = state.selectedSort;
        }
        history.push({ pathname: '/shop/products', state: { options } });
      }}
    />
  );

  const renderSort = () => (
    <div style={{ display: 'inline' }}>
      <MaterialButton
        className="action-button-outline"
        startIcon={<Icon name="chevrons-down" />}
        onClick={() => setShowSortDrawer(true)}
      >
        {'Sort'}
      </MaterialButton>
    </div>
  );

  const renderFilters = () => {
    if (isMobile) {
      return (
        <div style={{ display: 'inline' }}>
          <MaterialButton
            className="action-button-outline"
            startIcon={<Icon name="sliders" />}
            onClick={toggleSideDrawer}
          >
            {'Filter'}
          </MaterialButton>
        </div>
      );
    }
    return (
      <div style={{ position: 'fixed', top: 64, right: 0, height: 'calc(100vh - 64px)', width: '20%', paddingRight: 30 }}>
        <CustomScroll autoHide heightRelativeToParent="100%">
          <div>
            {renderShopFilter()}
          </div>
        </CustomScroll>
      </div>
    );
  };

  const renderLoadingCard = () => (
    <div>
      <div style={{ padding: 5 }}>
        <Skeleton height={150} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );

  const renderSponsorBadge = () => (
    <Badge
      pill
      style={{ marginLeft: 10, color: '#9f9f9f' }}
      className="card-header-badge"
      onClick={() => {
        ModalDialog.show({
          title: 'Sponsored',
          message: 'You\re seeing this ad because of its relevancy to the search filters and/or your personal cigar preferences.',
        });
      }}
    >
      {'Sponsored'}
    </Badge>
  );

  const renderAffiliateBadge = (withMargin = true) => (
    <Badge
      pill
      style={{ marginLeft: withMargin ? 10 : 0, color: '#9f9f9f' }}
      className="card-header-badge"
      onClick={() => {
        ModalDialog.show({
          title: 'Affiliated Partner',
          message: 'This business is an affiliated partner. That means that if you follow a link to their website from ours through a button, banner ad, or any other means and buy any of the products they offer, we\'ll receive a small commission. This commission is not charged to you, the customer, and we only partner with sites we trust.',
        });
      }}
    >
      {'Affiliated Partner'}
    </Badge>
  );

  const renderProductCard = (product, i, index) => {
    let price = product.msrp;
    if (product.sale_price) {
      price = product.sale_price;
    }

    let formattedPrice = price;
    if (typeof price === 'number') {
      formattedPrice = `$${price.toFixed(2)}`;
    } else if (typeof price === 'string' && price.indexOf('$') === -1) {
      formattedPrice = `$${parseFloat(price).toFixed(2)}`;
    }

    if (window.analytics) {
      window.analytics.track('Product Impressions', {
        product_id: product.id,
        impression_type: 'shop_all',
        user_id: auth && auth.user && auth.user.id,
      });
    }

    return (
      <div key={`shopall-${index}-${i}`} className="shop-all-product-card">
        <span>
          <div
            style={{
              textAlign: 'center',
              padding: 5,
            }}
          >
            <img
              src={getImage(product)}
              alt={productTitle(product)}
              style={{ maxWidth: '100%' }}
              onClick={() => showProductDetail(product)}
            />
            {product.sponsored && renderSponsorBadge(false)}
            {product.affiliate_url && renderAffiliateBadge()}
            <div onClick={() => showProductDetail(product)}>
              <div style={{ fontSize: 12 }}>{productTitle(product)}</div>
              <div>{packageTitle(product)}</div>
              <Rating
                initialRating={product.avg_rating / 20}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
              <div>{`${product.total_ratings || 0} ratings`}</div>
            </div>
            {formattedPrice && (
              <BuyButton
                product={new Product(product)}
                showPrefix={false}
              />
            )}
          </div>
        </span>
      </div>
    );
  };

  const renderSections = () => {
    console.log('Rendering sections');
    console.log(sections);
    if (loadingSections) {
      return (
        <div style={{ padding: 20 }}>
          <Skeleton width={100} height={40} />
          <ProductSlider {...settings}>
            {renderLoadingCard()}
            {renderLoadingCard()}
            {renderLoadingCard()}
          </ProductSlider>
          <Skeleton width={100} height={40} />
          <ProductSlider {...settings}>
            {renderLoadingCard()}
            {renderLoadingCard()}
            {renderLoadingCard()}
          </ProductSlider>
          <Skeleton width={100} height={40} />
          <ProductSlider {...settings}>
            {renderLoadingCard()}
            {renderLoadingCard()}
            {renderLoadingCard()}
          </ProductSlider>
        </div>
      );
    }
    if (isMobile) {
      return sections.map((section, index) => {
        if (section.products.length > 0) {
          return (
            <Card style={{ marginTop: 10, marginBottom: 20 }}>
              <div style={{ paddingBottom: 20 }}>
                <div>
                  <h4 style={{ paddingTop: 20, paddingLeft: 20 }}>{section.title}</h4>
                  {types.indexOf(section.title) !== -1 && (
                    <span
                      onClick={() => {
                        const options = { ...state.selectedFilterOptions };
                        options.type = section.title;
                        history.push({ pathname: '/shop/products', state: { options } });
                      }}
                      style={{ float: 'right', paddingRight: 20, marginTop: -44 }}
                    >
                      {'View All'}
                    </span>
                  )}
                </div>
                <div className="simple-carousel">
                  {section.title === '5 Packs' && (
                    <div className="shop-all-product-card">
                      <span>
                        <DynamicAd zoneId="103061" />
                      </span>
                    </div>
                  )}
                  {section.title === 'Samplers' && (
                    <div className="shop-all-product-card">
                      <span>
                        <DynamicAd zoneId="103062" />
                      </span>
                    </div>
                  )}
                  {section.title === 'Boxes' && (
                    <div className="shop-all-product-card">
                      <span>
                        <DynamicAd zoneId="103063" />
                      </span>
                    </div>
                  )}
                  {section.title === 'Singles' && (
                    <div className="shop-all-product-card">
                      <span>
                        <DynamicAd zoneId="103064" />
                      </span>
                    </div>
                  )}
                  {section.products.map((product, i) => renderProductCard(product, i, index))}
                </div>
              </div>
            </Card>
          );
        }
      });
    }
    return sections.map((section, index) => {
      if (section.products.length > 0) {
        return (
          <div style={{ padding: 20 }}>
            <h3>{section.title}</h3>
            <ProductSlider {...settings}>
              {
                section.products.map((product, i) => {
                  let price = product.msrp;
                  if (product.sale_price) {
                    price = product.sale_price;
                  }

                  let formattedPrice = price;
                  if (typeof price === 'number') {
                    formattedPrice = `$${price.toFixed(2)}`;
                  } else if (typeof price === 'string' && price.indexOf('$') === -1) {
                    formattedPrice = `$${parseFloat(price).toFixed(2)}`;
                  }

                  return (
                    <div className="card-padding">
                      <Card style={{ margin: 4, minHeight: isMobile ? 'auto' : 350 }}>
                        <div key={`shopall-${index}-${i}`}>
                          <Link to={`/products/${hashids.encode(product.id)}`}>
                            <div
                              style={{
                                textAlign: 'center',
                                padding: 5,
                              }}
                            >
                              <img src={getImage(product)} alt={productTitle(product)} style={{ maxWidth: '100%' }} />
                              <div style={{ fontSize: 16 }}>{productTitle(product)}</div>
                              <div>{packageTitle(product)}</div>
                              <Rating
                                initialRating={product.avg_rating / 20}
                                emptySymbol="far fa-star"
                                fullSymbol="fas fa-star"
                                fractions={2}
                                readonly
                                style={{ color: 'rgb(214, 194, 144)' }}
                              />
                              <div>{`${product.total_ratings || 0} ratings`}</div>
                              {formattedPrice && (
                                <BuyButton
                                  showPrefix
                                  product={new Product(product)}
                                />
                              )}
                            </div>
                          </Link>
                        </div>
                      </Card>
                    </div>
                  );
                })
              }
            </ProductSlider>
          </div>
        );
      }
    });
  };

  console.log('Rerender products');
  console.log(products);

  return (
    <PageWrap>
      <Helmet>
        <title>Shop Cigars | Boxpressd</title>
      </Helmet>

      <div>
        <div className="action-button-wrapper">
          <div style={{ padding: 10, float: 'right' }}>
            {isMobile && renderSort()}
            {isMobile && renderFilters()}
          </div>
          {isMobile && <div className="clearfix" />}
        </div>

        <div className="row no-margins">
          <div className={`${isTablet ? 'col-md-12' : 'col-md-9'} no-padding`}>
            {renderSections()}
          </div>
          <div className="col-md-3">
            {!isMobile && renderFilters()}
          </div>
        </div>

        { isMobile && renderShopFilter() }
      </div>
    </PageWrap>
  );
}

ShopAll.propTypes = {
  options: PropTypes.object,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  products: PropTypes.array,
  filterProducts: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  loading: selectProductsLoading()(state),
  errorMessage: selectProductErrorMessage()(state),
  currentPage: selectProductsCurrentPage()(state),
  hasMore: selectProductsHasMore()(state),
  products: selectProducts()(state),
});

function mapDispatchToProps(dispatch, props) {
  return {
    filterAllProducts: (page, options) => dispatch(requestProductsAction(page, options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopAll);
